import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/spotlight/styles.css';
import * as Sentry from '@sentry/react';
import { registerLicense } from '@syncfusion/ej2/base';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AppProviders } from './AppProviders';
import './assets/styles/app.scss';
import { setupAxios } from './auth/axios';

registerLicense('ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5adEZiXH9ZcnRQQmlc');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: 1000 * 60 * 60 * 24 * 1, // 1 Days
      refetchOnWindowFocus: false,
    },
  },
});

// const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage });

// persistQueryClient({
//   queryClient,
//   persister: localStoragePersister,
//   maxAge: 1000 * 60 * 60 * 24 * 1, // 1 Days
// });
Sentry.init({
  dsn: 'https://006074deef444d44bc7bbcd2cf78987f@o1030357.ingest.sentry.io/6677226',
  environment: import.meta.env.MODE,
  enabled: import.meta.env.MODE !== 'development',
  tracesSampleRate: import.meta.env.MODE === 'development' ? 1.0 : 0.2,
});

setupAxios(axios);
ReactDOM.createRoot(document.getElementById('root')!).render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter basename="/">
      {/* <React.StrictMode> */}
      <AppProviders />
      <ReactQueryDevtools initialIsOpen={false} />
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </QueryClientProvider>
);
